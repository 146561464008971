import Lottie from 'react-lottie';
import animationData from '../LOTTIES/loader.json';
import animationDataWhite from '../LOTTIES/loader_white.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};



function LottieLoader(props)
{
    var transparent = props.transparent
    if (transparent === null || transparent === undefined) {
        transparent = true
    }

    if (props.isWhite) {
        defaultOptions.animationData = animationDataWhite
    }

    return (
        <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={transparent ? '' : 'lottie_loader'}>
            <Lottie style={{ position: 'fixed', zIndex: 15, width: '15rem', height: '4rem' }}
                options={defaultOptions}
                height={100}
                width={100}
            />
        </div>
    )
}



export default LottieLoader
