import loadable from '@loadable/component';
import 'stream-chat-react/dist/css/index.css';
import './App.css';
import React, { useState, useEffect } from "react";
import UserContext from "./context/context";
import { createBrowserHistory } from 'history';
import _ from 'lodash'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { ObjectId } from "mongoose";
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import ReactGA from 'react-ga4'
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import LottieLoader from './components/LOTTIES/loader';
import DM from './components/MAIN/DM';//cannot be in loadable

const Main = loadable(() => import('./components/MAIN/Main'));
const ProfileDetails = loadable(() => import('./components/PROFILE/ProfileDetails'));
const EditUser = loadable(() => import('./components/PROFILE/EditUser'));
const Login = loadable(() => import('./components/LOGIN/LoginForm'));
const ScratchCardDetail = loadable(() => import('./components/SCRATCHGAME/ScratchCardDetail'));
import { LocalKey, LocalStorage } from "ts-localstorage";
import Contact, { Status } from './models/Contact';
import axios from 'axios';
import Profile, { Account } from './models/Profile';
import { getRandomInt } from './helper/Math';


const history = createBrowserHistory({ forceRefresh: true })

function App() {
  const userKey = "user" as LocalKey<Profile>;
  const contactsKey = "contacts" as LocalKey<Contact[]>;
  const profilesKey = "profiles" as LocalKey<Profile[]>;
  const referralKey = 'referral' as LocalKey<string>;

  const [user, setUser] = useState<Profile | undefined>(LocalStorage.getItem(userKey) as Profile | undefined)
  const [contacts, setContacts] = useState<Contact[] | undefined>(LocalStorage.getItem(contactsKey) as Contact[] | undefined);
  const [profiles, setProfiles] = useState<Profile[] | undefined>(LocalStorage.getItem(profilesKey) as Profile[] | undefined);
  const [snackbarVisible, setsnackbarVisible] = React.useState(false);
  const [snackbarText, setSnackbarText] = useState<string>("")
  const [snackbarSeverity, setSnackbarSeverity] = useState<Color>('success')
  const [snackbarTime, setSnackbarTime] = useState(3000)


  const loadProfiles = async (): Promise<Profile[] | undefined> => {
    return new Promise<Profile[] | undefined>((resolve, reject) => {
      const user = LocalStorage.getItem(userKey) as Profile | undefined
      setUser(user)

      const contacts = LocalStorage.getItem(contactsKey) as Contact[] | undefined
      setContacts(contacts)

      console.debug('contacts: ', contacts)
      //get existing contact ids (as string) to filter them when getting new profiles
      const contactids = _.map(contacts, (contact: Contact) => {
        return contact?.profile?._id
      })

      console.log("contactids.toString()", contactids)



      //console.log('User from disk', user)

      const query = {
        latitude: _.get(user, 'preferences.geojson.coordinates[0]'),
        longitude: _.get(user, 'preferences.geojson.coordinates[1]'),
        locationdistance: _.get(user, 'preferences.locationdistance'),
        userid: _.get(user, '_id'),
        existingcontacts: contactids,
        lookfor: _.get(user, 'preferences.lookfor'),
        minage: _.get(user, 'preferences.minage'),
        maxage: _.get(user, 'preferences.maxage'),
        owngender: _.get(user, 'person.gender')
      }
      console.debug("Profile query ", query)
      //console.log(user)

      axios.post<Profile[]>('/.netlify/functions/getProfiles', JSON.stringify(query))

        .then(response => {
          console.debug(response.data);
          setProfiles(response.data);
          LocalStorage.setItem(profilesKey, response.data)
          console.debug("Loaded new profiles from service", response.data)

          resolve(response.data)

        })
        .catch(errorMessage => {
          console.error('Could not load profiles2', errorMessage)
          reject('Could not load profiles2')
        });

    })

  }

  const loadContacts = async (): Promise<Contact[] | undefined> => {

    return new Promise<Contact[] | undefined>((resolve, reject) => {
      const u = LocalStorage.getItem(userKey) as Profile | undefined
      setUser(u)

      const query = {
        user_id: _.get(u, '_id'),
       // status: 'active',
      }

      console.log(query)
      axios.post<Contact[]>('/.netlify/functions/getContacts', JSON.stringify(query))
        .then(response => {
          console.debug(response.data);
          //  setContacts(response.data);
          LocalStorage.setItem(contactsKey, response.data)
          console.debug("Loaded contacts from service")

          setContacts(response.data)

        })
        .catch(errorMessage => {
          console.error('Could not load contacts', errorMessage)
        });

      resolve(contacts);
    })

  }

  const loadUser = async (): Promise<Profile | undefined> => {

    return new Promise<Profile | undefined>((resolve, reject) => {
      const u = LocalStorage.getItem(userKey) as Profile | undefined

      if (_.get(u, 'account.email') !== undefined) {
        const query = {
          email: _.get(u, 'account.email')
        }
        /* if (_.get(u, 'account.googleid') !== undefined) {
        const query = {
          googleid: _.get(u, 'account.googleid')
        } */

        console.log(query)
        axios.put<Profile>('/.netlify/functions/getUser', JSON.stringify(query))
          .then(response => {
            console.debug(response.data);

            LocalStorage.setItem(userKey, response.data)
            console.debug("Loaded user from service")

            setUser(response.data)

          })
          .catch(errorMessage => {
            console.error('Could not load user', errorMessage)
          });

        resolve(user);
      }
    })

  }



  const loadContactsFromDisk = () => {
    const cntcs = LocalStorage.getItem(contactsKey)
    if (cntcs !== null) {
      setContacts(cntcs)
      return cntcs
    } else {
      return []
    }


  }



  const providerValue = {
    user,
    contacts,
    profiles,
    addToContactList,
    updateUser,
    updateContact,
    logOutUser,
    logIn,
    goHome,
    doSnack,
    unBlur,
    getContactByProfileId,
    loadProfiles,
    loadContacts,
    removeContact,
    loadContactsFromDisk,
    addOppositeContact,
    saveReferral,
    loadUser

  };




  useEffect(() => {

    /*   (async () => { */
    const u = LocalStorage.getItem(userKey) as Profile | undefined

    if (u) {
      loadUser() //get the most recent one from server after refresh
      loadContactsFromDisk() //fast
      loadContacts()

    } else {
      //if there is no user existing yet
      const tempUser = new Profile()
      tempUser.account = new Account()
      tempUser.account.coinamount = parseInt(process.env.REACT_APP_COINS_NEW_USER as string)
      updateUser(tempUser)

    }

    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string)

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    /*     })() */

  }, []);


  interface AlertInterface extends AlertProps {
    onClose: any;
    severity: Color;
    message: string;
  }

  //snackbar
  function Alert(props: AlertInterface) {
    return <MuiAlert elevation={6} variant="filled" severity={props.severity} onClose={props.onClose}  >{props.message}</MuiAlert>;
  }


  const handleClose = (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setsnackbarVisible(false);
  };

  //severity: error, warning, success 
  function doSnack(text: string, severity: Color, time: number) {



    setSnackbarText(text)
    setSnackbarSeverity(severity)
    setsnackbarVisible(true)

    if (time) {
      setSnackbarTime(time)
    } else {
      setSnackbarTime(3000)
    }
  }
  //end snackbar


  async function updateUser(updatedUser: Profile) {


    // console.log("update user", updatedUser);
    setUser(updatedUser);
    LocalStorage.setItem(userKey, updatedUser)

    if (updatedUser?._id === null || updatedUser?._id === undefined) { //could be happening when a user is not logged in
      return
    }

    console.log("Update user/profile query: ", updatedUser)

    axios.put('/.netlify/functions/updateProfile', JSON.stringify(updatedUser))
      .then(response => {
        console.debug(response.data);

        console.debug("Updated user on service", response.data)
        const res = response.data

        if (res === undefined) {
          console.error('Could not update user')
        }

      })
      .catch(errorMessage => {
        console.error('Could update user', errorMessage)
      });

  }


  async function logIn(user: Profile) {
    //console.log("log in", user)
    if (user) {
      setUser(user)
      LocalStorage.setItem(userKey, user);
    }
    //console.log('logged in user: ', user)

  }

  function logOutUser() {
    console.log("log off");

    LocalStorage.removeItem(userKey)
    LocalStorage.removeItem(contactsKey)
    LocalStorage.removeItem(profilesKey)
    LocalStorage.removeItem(referralKey)

    history.push("/");
  }

  function goHome() {
    console.log("Go home");
    history.push("/");
  }




  //reverse the contact and user
  async function addOppositeContact(contactId: string) {

    if (user) {
      //create a temp contact from profile data
      const tempContact = new Contact()
      tempContact.status = new Status('new', new Date())
      tempContact.bluramount = parseInt(process.env.REACT_APP_BLUR_AMOUNT as string)
      tempContact.profile = Profile.removeSensitiveDataFromProfile(user) //add this cleaned user as the opposite profile

      await updateContact(tempContact, contactId)

      console.log('opposite', tempContact)
    }
  }

  //After pressing 'get new match'
  async function addToContactList(profile: Profile) {
    const newList = profiles?.filter((item) => item._id !== profile._id);

    if (newList !== undefined && newList.length > 0) {
      _.remove(newList, n => n._id === user?._id) //remove yourself if neccessary
      LocalStorage.setItem(profilesKey, newList)
      setProfiles(newList);
    }

    //todo if not a goldmember, blur new contacts & get the score amount for backend 
    //create a temp contact from profile data
    const tempContact = new Contact()
    tempContact.status = new Status('active', new Date())
    tempContact.bluramount = parseInt(process.env.REACT_APP_BLUR_AMOUNT as string)
    tempContact.profile = profile
    tempContact.contactdate = new Date()
    tempContact.scoreamount = getRandomInt(0, 100) //todo */

    updateContact(tempContact)

    const oldContacts = loadContactsFromDisk()

    const tempContacts = oldContacts ? [tempContact, ...oldContacts] : [tempContact]

    LocalStorage.setItem(contactsKey, tempContacts)

    await loadContacts()

  }

  //explicit user id is only to be used when using it for the opposite contact function
  async function updateContact(contact: Contact, explicitUserId?: string) {
    try {

      //console.log('error at contact:', contact)

      if (contact?.profile?._id === undefined) {
        throw new Error('profile id empty, must not happen')
      }
      if (user === undefined) {
        throw new Error('user empty, must not happen')
      }

      const query = {
        user_id: explicitUserId ?? user?._id,
        contact_id: contact?.profile?._id,
        bluramount: contact?.bluramount,
        status: contact?.status?.status,
        contactdate: contact?.contactdate
      }

      console.log("Update contact query: ", query)

      const data = await fetch('/.netlify/functions/updateContact', {
        method: 'POST',
        body: JSON.stringify(query),
      });

      const res = await data.json();

      const { ok, err } = res
      if (ok) {
        console.log("Contact saved to database", res);
      } else {
        console.error(err);
      }


    } catch (err) {
      console.error(err);
    }
  }

  async function removeContact(contact: Contact) {
    _.set(contact, 'status', 'disabled')

    console.log('Remove contact:: ', contact)
    await updateContact(contact)

    doSnack(`${contact?.profile?.person?.nickname} is removed from your contact list`, "success", 4000)


  }

  //referral is id of a user 
  async function saveReferral(referral: string) {
    LocalStorage.setItem(referralKey, referral)
  }

  ///blur starts with 10 (=100%) every message subtracts 1 
  async function unBlur(contactId: string, amount: number) {
    console.debug('contact id to unblur: ', contactId)

    const contactToUnBlur = getContactByProfileId(contactId)
    if (contactToUnBlur !== undefined) {

      contactToUnBlur.contactdate = new Date()
      let bluramount = contactToUnBlur?.bluramount ? contactToUnBlur?.bluramount - amount : 10
      if (bluramount < 0) {
        bluramount = 0
        return;
      }

      contactToUnBlur.bluramount = bluramount
      contactToUnBlur.status = new Status('active', new Date())
      contactToUnBlur.contactdate = new Date()

      console.debug('temp contact: ', contactToUnBlur)
      await updateContact(contactToUnBlur)

      if (bluramount < 1) {
        await loadContacts()

        doSnack(`You can see all pictures of ${contactToUnBlur?.profile?.person?.nickname} now!`, 'success', 3000)
      }
      ReactGA.event({
        category: 'engagement',
        action: 'ublur_by_amount'
      });


    } else {
      console.debug("Contact to unblur was null, so probably try to unblur it self")
    }

  }


  function getContactByProfileId(profileId: string): Contact | undefined {

    const contact = contacts?.find(c => c.profile?._id === profileId);

    if (contact) {
      return contact
    } else return undefined
  }


  const isProduction = process.env.NODE_ENV === 'production';


  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<LottieLoader transparent={false} isWhite={true} />}  //If not pass, nothing appears at the time of new version check.
    >
      <Router >
        <UserContext.Provider value={providerValue}>
          <div>
            {
              <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/referral:refid" component={Main} />
                <Route exact path="/profile/:id" component={ProfileDetails} />
                <Route exact path="/edituser" component={user?._id && user?._id ? EditUser : Login} />
                <Route exact path="/edituser/:id" component={EditUser} />
                <Route exact path="/DM" component={user?._id && user?._id ? DM : Login} />
                <Route exact path="/DM/:id" component={user?._id && user?._id ? DM : Login} />
                <Route path="/scratch" exact component={ScratchCardDetail} />
                <Route path="*" component={Main} />
              </Switch>
            }

            <Snackbar open={snackbarVisible} autoHideDuration={snackbarTime} onClose={handleClose} >
              <Alert onClose={handleClose} severity={snackbarSeverity} message={snackbarText} ></Alert>
            </Snackbar>
          </div>
        </UserContext.Provider>
      </Router>
    </CacheBuster>
  );
}

export default App;
