import { StreamChat } from "stream-chat";

var client;
var user;
var context;

export function getClient(userParam) {

  if (client === undefined || client === null) {
        client =  StreamChat.getInstance(process.env.REACT_APP_CHAT_API_ID);

        user = userParam
    if (user._id) {
      connectUser(user._id, user.person?.nickname); //this also creates a new user if not existed before
      console.log("Chat connected user: ", user.person?.nickname, user._id)
    }

    console.info("*** Retrieved new chat client, should only be once");
  }
  console.info("Chat client recycled, top!")
  return client;
}

// disable auth checks, allows dev token usage //todo make this for production
export async function disableAuthChecks(disableBool) {
  const c = await getClient();

  c.updateAppSettings({
    disable_auth_checks: disableBool,
  });
}

//todo this is still in development
export async function connectUser(userId, userName) {

    getClient().connectUser(
    {
      id: userId,
      name: userName,
      image: `https://getstream.io/random_svg/?name=${userName}`, //todo use the image in user object
    },
    getClient().devToken(userId)
  );
}
//this is for debug. Normal situation, use logoff
export async function disconnectUser() {
    getClient().disconnectUser();
}

export async function removeChannelById( id) {
 
  const channel = getClient().channel("messaging", id);
  await channel.delete();

  context?.doSnack('Chat removed', 'succes', 4000)
}

//remove *channels* with person id
export async function removeChannelWithPersonId(personId) {
 

  const filter = { type: "messaging", members: { $in: [personId] } };
  const sort = [{ last_message_at: -1 }];

  const channels = getClient().queryChannels(filter, sort, {
    watch: true, // this is the default
    state: true,
  });

  for (const channel of channels) {
    await channel.delete();
  }
}

export async function createChannel(context,channelId, channelName, memberArray, setChannel) {

  console.log("Create channel with: ", memberArray)

  try {

    var channel = getClient().channel("messaging", channelId, {
      name: channelName,
      members: memberArray,
    });

    await channel.watch();
    //await channel.enableSlowMode(0.5); 

    setChannel(channel) //this disables the channellist button to select other channels

   


    //set notifications works but gives couple of renderings too much
  /*  await channel.watch();
     await channel.on(event => {
      if (event.type === 'message.new' && event.unread_count > 0) {

        console.log("try to create new notification", event) 
        
        new Notification(event.user.name, {
          body: event.message.text,
        });

        document.getElementById('favicon').href =
          'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/google/223/bell_1f514.png';
      }

      if (event.type === 'message.read' && !event.total_unread_count) {
        document.getElementById('favicon').href = '/favicon.ico';
      }
    });  */
    //end notifications

  } catch (error) {
    console.error("CANNOT CREATE CHANNEL, USER DOES NOT EXIST", error)

    //context.doSnack('Could not create chat' + error.toString(), 'error', 8000)
    context.doSnack('Could not create chat, something went wrong', 'error', 4000)
  } 

 
 
}


export async function renameChannel(from, to) {
  const channel = getClient().channel("messaging", from);
  await channel.updatePartial({ set: { name: to } });
}

export function getFilterByUserId(personId)
{
    const filters = {
        type: "messaging",
        members: { $in: [personId] },
      };

      return filters
}
