import { useContext, React, useEffect } from "react";
import ProfileIcon from "@material-ui/icons/AccountCircleOutlined";
import "./header.css";
import "../../index.css";
import UserContext from "../../context/context";
import { Link } from "react-router-dom";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import Logo from "../../images/heart.webp";
function Header(props)
{
  const context = useContext(UserContext)
  const history = useHistory();
  const profileImage = `https://ucarecdn.com/${context.user?.properties?.pictures[0]}`


  return (
    <header>
      <Link to="/" style={{ textDecoration: "none", cursor: 'pointer' }}>
          <div className='header' style={{position:'absolute'}} >
            <div className="headerImage">
              <img
                width="100%"
                src={Logo}
                alt="Back home" />
            </div>
            <span className='main_logo_text'>CatchaMatch</span>
          </div>
        </Link>

      <div className="headerButtons" style={{ marginTop: '0.5rem', }}>

        <span  style={{ color:'white', fontFamily: 'Dillan', fontSize: '20px', alignSelf: 'center', justifySelf: 'center' }}>Your messages</span>


        <ArrowBack onClick={() =>
        {
          history.goBack();
        }}
          style={{ color: "#CCC" }}
          fontSize="large"
        />



        <Link to="/edituser">
          {context.user?.properties?.pictures[0] ?
            <div>
              <img src={profileImage} style={{ width: '32px', height: '32px', borderRadius: '50px', imageSize: 'cover' }} />
            </div>
            :
            <ProfileIcon
              style={{ color: "#FFF" }}
              fontSize="large"
            />
          }
        </Link>
      </div>

    </header>
  );

}

export default Header;
