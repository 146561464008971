import React, { useEffect, useState, useContext, useRef } from "react";
import HeaderMail from "../HEADER/HeaderMail";
import { useHistory } from "react-router-dom";
import { getRandomInt } from '../../helper/Math'
import
{
  Chat,
  Channel,
  ChannelList,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  MessageInputFlat,
  ChannelHeader
} from "stream-chat-react";
import UserContext from "../../context/context";
import { getClient, getFilterByUserId, createChannel } from "../../helper/ChatHelper";
import { Helmet } from "react-helmet";

import { ChannelPreviewMessenger } from '../GETSTREAM/ChannelPreviewMessenger';
import ReactGA from 'react-ga4'
import LottieLoader from '../../components/LOTTIES/loader';
import ObjectID from "bson-objectid";

//todo this needs to be SERVER SIDE
//const userToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiY2FsbS1tb3JuaW5nLTEiLCJleHAiOjE2MjAxNTE4NzB9.IZEjtS4y--DLysHXPlBRxtFigQ5t2rsk6-O8T01dzd8';



const DM = ({ match }) =>
{
  const { id } = match.params;
  const [chatClient, setChatClient] = useState(null);

  const [user] = useState(JSON.parse(localStorage.getItem('user'))) //this is you
  const history = useHistory();
  const context = useContext(UserContext);
  const [channel, setChannel] = useState()
  const [showNotificationBanner, setShowNotificationBanner] = useState(false);
  const [contact, setContact] = useState(context.getContactByProfileId(id));
  //filter the chat only for the logged in person
  const [filters, setFilters] = useState(getFilterByUserId(user._id))
  const [sort, setSort] = useState({ last_message_at: -1 })
  const [loading, setLoading] = useState(false)
  const messagesEndRef = useRef(null)





  const scrollToBottom = () =>
  {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }



  useEffect(() =>
  {
    document.title = "Messages"
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    if (localStorage.getItem("user") === null) {
      alert("Please login first");
      history.push("/edituser");
      return <></>;
    }

    const initChat = async () =>
    {
      setLoading(true)

      await setChatClient(getClient(user));

      //talking with someone? Or just opening the mailbox
      if (contact) {
        console.log("talking to ", contact);

        //quick hack to always have unique channel ids, no matter who is creating them
        const userObjectIdTs = new ObjectID(user._id.toString()).getTimestamp()
        const contactObjectIdTs = new ObjectID(contact?.profile?._id.toString()).getTimestamp()

        var channelId = ''
        if (userObjectIdTs < contactObjectIdTs) {
          channelId = user._id.toString() + '-' + contact?.profile?._id.toString()
        } else {
          channelId = contact?.profile?._id.toString() + '-' + user._id.toString()
        }

        //client.channel('messaging', {   members: ['thierry', 'tommaso']
        await createChannel(context, channelId, user.person?.nickname + '|' + contact?.profile?.person?.nickname, [`${user._id}`, `${contact?.profile?._id}`], setChannel)
        //   console.log("Channel created ", channelId);

        //also create the contact card for the receiving user //todo create an acceptance setction for 'attractive profiles' that gets a lot of fans
        //so reverse the ids user = contact and contact = user
        context.addOppositeContact(contact?.profile?._id)

      }

      const channels = await getClient(user).queryChannels(filters, sort, {
        watch: true, // this is the default 
        state: true,
      });

      console.log("Available channels: ", channels)


      for (const c of channels) {



        await c.on(event =>
        {
          if (event.type === 'message.new') {
            //some one is talking on this channel, make it less blurry for the person //todo make the algoritm more interesting

            console.log(event.message)
            context.unBlur(event.message.user.id, 1)
            //notifications
            if (event.unread_count > 0) {
              console.log("try to create new notification", event)

              var notification = new Notification(event.user.name, {
                icon: 'https://www.catchamatch.com/static/media/heart.0e8179e2.webp',
                body: event.message.text,
              });

              notification.onclick = function (e)
              {

                window.open('http://www.catchamatch.com/dm/', '_blank');
                // parent.focus();
                window.focus(); //just in case, older browsers
                this.close();
              };
            }


            /* if (event.type === 'message.read' && !event.total_unread_count) {

            } */
          }
        }
        );
        //end notifications


      }
      setLoading(false)
    }
    initChat();

    //notification 
    if (
      window.Notification &&
      (Notification.permission === 'granted' ||
        Notification.permission === 'denied')
    )
      return;

    setShowNotificationBanner(true);


  }, [history]);

  //notification permission
  function grantPermission()
  {
    if (Notification.permission === 'granted') {
      new Notification('You are already subscribed to notifications');
      return;
    }

    if (
      Notification.permission !== 'denied' ||
      Notification.permission === 'default'
    ) {
      Notification.requestPermission().then(result =>
      {
        if (result === 'granted') {
          navigator.serviceWorker.getRegistration().then(function (reg)
          {
            new Notification('Hi! CatchaMatch here', {
              body: 'Notifications are enabled'
            });
          });

        }
      });
    }

    setShowNotificationBanner(false);
  }
  //end notification



  if (!chatClient && !channel) {
    return <LoadingIndicator />;
  }

  scrollToBottom()

  const CustomListItem = (props) =>
  {
    const isActive = (props?.channel?.data?.id === channel?.data?.id)

    return (<ChannelPreviewMessenger
      active={isActive}
      Avatar={props.Avatar}
      channel={props.channel}
      displayImage={props.displayImage}
      displayTitle={props.displayTitle}
      latestMessage={props.latestMessage}
      setActiveChannel={setChannel}
      unread={props.unread}
      watchers={props.watchers}
      context={context} />)
  };



  return (
    <div>

      {<Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=0.86, maximum-scale=0.86, user-scalable=no" />
      </Helmet>}
      <div className="str-chat" >
        {loading && <LottieLoader transparent={true} />}

        <HeaderMail channel={channel} />
        <Chat client={chatClient} theme="messaging light">
          {showNotificationBanner && (
            <div className="alert" >
              <p >
                CatchaMatch needs your permission to&nbsp;
                <button onClick={grantPermission}>
                  enable chat notifications
                </button>
              </p>
            </div>
          )}
          <ChannelList filters={filters} sort={sort} showSidebar={false} Preview={CustomListItem} />
          <Channel channel={channel}>
            <div className="str-chat__main-panel" >
              <ChannelHeader title=' ' />
              <div />
              <MessageList />
              <MessageInput Input={MessageInputFlat} ref={messagesEndRef} />
            </div>
            <Thread />
          </Channel>
        </Chat>
      </div>

    </div>

  );
};

export default DM;
