import React, { useState, useRef, useCallback } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from '@material-ui/icons/Delete';
import Unblur from '@material-ui/icons/RemoveRedEye';


const AlertDialog = props => {
  const [open, setOpen] = useState(false);
  const state = useRef({
    open: false
  });
  const handleClickOpen = useCallback(() => {
    setOpen(true);
  });
  const handleClose = useCallback(() => {
    setOpen(false);
  });
  const handleAgree = useCallback(() => {
    console.log("I agree!");
    handleClose();

    props.handleAgree()
  });
  const handleDisagree = useCallback(() => {
    console.log("I do not agree.");
    handleClose();
  });

  let iconObj = { "delete": <DeleteIcon style={{fontSize:'18px'}} />, "unblur": <Unblur /> } //todo extend this with more icons


  return <div>
    {
      /* Button to trigger the opening of the dialog */
    }
    <div onClick={handleClickOpen} style={{ cursor:'pointer', display: "flex", alignItems: "center", justifyContent: "space-around", color: props.color, left: props.leftPosition, top: props.topPosition,  fontSize: "12px", paddingLeft: "1rem", paddingRight: "1rem", textTransform: "uppercase" }}>
      {iconObj[props.icon]} {props.buttonText}
    </div>
    {
      /* Dialog that is displayed if the state open is true */
    }
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {props.title || "Alert"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.subTitle || "Alert text"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisagree} color="primary">
          {props.cancelText || "Cancel"}
        </Button>
        <Button onClick={handleAgree} color="primary" autoFocus>
          {props.okText || "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  </div>;
};

export default AlertDialog;
