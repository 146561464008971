import { Color } from '@material-ui/lab/Alert';
import { resolve } from 'path/posix';
import * as React from 'react'
import Contact from '../models/Contact'
import Profile from '../models/Profile'

export type UserContextState = {

    user: Profile | undefined;
    profiles: Profile[] | undefined;
    contacts: Contact[] | undefined;

    addToContactList: (profile: Profile) => void;
    updateUser: (updatedUser: Profile) => void;
    updateContact: (contact: Contact) => void;
    logOutUser: () => void;
    goHome: () => void;
    doSnack: (text: string, severity: Color, time: number) => void;
    unBlur: (contactId: string, amount: number) => void;
    logIn: (user: Profile) => void;
    removeContact: (contact: Contact) => void;
    addOppositeContact: (contactId: string, userId: string) => void;
    saveReferral: (referral: string) => void;
    loadContactsFromDisk: () => Contact[];
    getContactByProfileId: (profileId: string) => Contact | undefined;
    loadProfiles: () =>  Promise<Profile[] | undefined>;
    loadContacts:  () =>  Promise<Contact[]| undefined>;
    loadUser:() => Promise<Profile | undefined>;


}

const contextDefaultValues: UserContextState = {

    user: undefined,
    profiles: undefined,
    contacts: [],
    addToContactList: () => ({}),
    updateUser: () => ({}),
    updateContact: () => ({}),
    logOutUser: () => ({}),
    goHome: () => ({}),
    doSnack: () => ({}),
    unBlur: () => ({}),
    logIn: () => ({}),
    removeContact: () => ({}),
    addOppositeContact: () => ({}),
    saveReferral: () => ({}),
    loadContactsFromDisk: () => [],
    getContactByProfileId: () => new Contact(),
    loadProfiles: () =>  Promise.resolve([]),
    loadContacts:  () => Promise.resolve([]),
    loadUser: () => Promise.resolve({}),
};


const UserContext = React.createContext<UserContextState>(contextDefaultValues)

export default UserContext