
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function getRandomIntExceptFor(min, max, exception) {
  min = Math.ceil(min);
  max = Math.floor(max);
  const result = Math.floor(Math.random() * (max - min) + min)

  if(result === exception) {
   return  getRandomIntExceptFor(min, max, exception)
  }
  return result; //The maximum is exclusive and the minimum is inclusive
}

//Fisher-Yates algorithm
export function shuffleArray(array) {
     for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  return array
}
