
import Profile from './Profile';

export default class Contact {

  contactdate?: Date;
  status?: Status
  bluramount?: number;
  scoreamount?: number;
  _id?: string;
  profile?: Profile;



  constructor(
    contactdate?: Date,
    bluramount?: number,
    scoreamount?: number,
    _id?: string,
    profile?: Profile
  ) {
    this.contactdate = contactdate
    this.bluramount = bluramount
    this.scoreamount = scoreamount
    this._id = _id
    this.profile = profile
  }


}

export class Status {
  status: string;
  statusdate: Date;


  constructor(status: string, contactdate: Date) {
    this.status = status
    this.statusdate = contactdate
  }

}