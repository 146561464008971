

export default class Profile {
    _id?: string;
    person?: Person;
    account?: Account;
    properties?: Properties;
    preferences?: Preferences;

  constructor(
    _id?: string, 
    person?: Person, 
    account?: Account, 
    properties?: Properties, 
    preferences?: Preferences
) {
    this._id = _id
    this.person = person
    this.account = account
    this.properties = properties
    this.preferences = preferences
  }


  static removeSensitiveDataFromProfile(profile: Profile): Profile {
    profile.account = undefined
    return profile
  }
}

class Person {
    title?: string;
    firstname?: string;
    middlename?: string;
    lastname?: string;
    gender?: string;
    phone?: string;
    cell?: string;
    nat?: string;
    age?: number;
    birthdate?: Date;
    nickname: string;
    location?: Location;




  constructor(
    nickname: string, 
    title?: string, 
    firstname?: string, 
    middlename?: string, 
    lastname?: string, 
    gender?: string, 
    phone?: string, 
    cell?: string, 
    nat?: string, 
    age?: number, 
    birthdate?: Date, 
    location?: Location
) {
    this.nickname = nickname
    this.title = title
    this.firstname = firstname
    this.middlename = middlename
    this.lastname = lastname
    this.gender = gender
    this.phone = phone
    this.cell = cell
    this.nat = nat
    this.age = age
    this.birthdate = birthdate
    this.location = location
  }
    

}

class Location {
    streetnumber?: string;
    streetname?: string;
    city?: string;
    state?: string;
    country?: string;
    postcode?: string;
    timezoneoffset?: string;
    timezonedescription?: string;

  constructor(
    streetnumber?: string, 
    streetname?: string, 
    city?: string, 
    state?: string, 
    country?: string, 
    postcode?: string, 
    timezoneoffset?: string, 
    timezonedescription?: string
) {
    this.streetnumber = streetnumber
    this.streetname = streetname
    this.city = city
    this.state = state
    this.country = country
    this.postcode = postcode
    this.timezoneoffset = timezoneoffset
    this.timezonedescription = timezonedescription
  }


   


}
export 
class Account {
    email?: string;
    username?: string;
    password?: string;
    logindate?: Date;
    registerdate?: Date;
    paidplan?: string;
    paidplanuntil?: string;
    role?: string;
    terms?: boolean;
    archived?: boolean;
    coinamount?: number;
    googleid?: string;



  constructor(
    email?: string, 
    username?: string, 
    password?: string, 
    logindate?: Date, 
    registerdate?: Date, 
    paidplan?: string, 
    paidplanuntil?: string, 
    role?: string, 
    terms?: boolean, 
    archived?: boolean, 
    coinamount?: number, 
    googleid?: string
) {
    this.email = email
    this.username = username
    this.password = password
    this.logindate = logindate
    this.registerdate = registerdate
    this.paidplan = paidplan
    this.paidplanuntil = paidplanuntil
    this.role = role
    this.terms = terms
    this.archived = archived
    this.coinamount = coinamount
    this.googleid = googleid
  }

    


}

class Properties {

    description?: string;
    quote?: string;
    interests?: string;
    knowabouts?: string;
    pros?: string;
    cons?: string;
    languages?: string[];
    whatyouwillbeinfiveyears?: string;
    schoolname?: string;
    pictures?: string[];
    educationlevel?: string;
    length?: number;
    weight?: number;
    kidsamount?: number;


  constructor(
    description?: string, 
    quote?: string, 
    interests?: string, 
    knowabouts?: string, 
    pros?: string, 
    cons?: string, 
    languages?: string[], 
    whatyouwillbeinfiveyears?: string, 
    schoolname?: string, 
    pictures?: string[], 
    educationlevel?: string, 
    length?: number, 
    weight?: number, 
    kidsamount?: number
) {
    this.description = description
    this.quote = quote
    this.interests = interests
    this.knowabouts = knowabouts
    this.pros = pros
    this.cons = cons
    this.languages = languages
    this.whatyouwillbeinfiveyears = whatyouwillbeinfiveyears
    this.schoolname = schoolname
    this.pictures = pictures
    this.educationlevel = educationlevel
    this.length = length
    this.weight = weight
    this.kidsamount = kidsamount
  }
    


}



class Preferences {
    minage?: number = 18;
    maxage?: number = 60;
    lookfor?: string;
    lookfordate?: string;
    sexuality? : string;
    locationdistance?: number = 30;
    metrics?: Metrics;
    geojson?: Geojson;



  constructor(
    minage?: number , 
    maxage?: number , 
    lookfor?: string, 
    lookfordate?: string, 
    sexuality? : string,
    locationdistance?: number , 
    metrics?: Metrics, 
    geojson?: Geojson
) {
    this.minage = minage
    this.maxage = maxage
    this.lookfor = lookfor
    this.lookfordate = lookfordate
    this.sexuality = sexuality
    this.locationdistance = locationdistance
    this.metrics = metrics
    this.geojson = geojson
  }
  


   


}

class Geojson {

    type: string = 'Point';
    coordinates: number[] = [0, 0];

  constructor(type: string , coordinates: number[] ) {
    this.type = type
    this.coordinates = coordinates
  }



  


}





class Metrics {
    smoking?: number = 5;
    smokingother?: number = 5;
    smokingimportance?: number = 5;
    drugs?: number = 5;
    drugsother?: number = 5;
    drugsimportance?: number = 5;
    lengthother?: number = 5;
    lengthimportance?: number = 5;
    weightother?: number = 5;
    weightimportance?: number = 5;
    education?: number = 5;
    educationother?: number = 5;
    educationimportance?: number = 5;
    attractiveness?: number = 5;
    attractivenessother?: number = 5;
    attractivenessimportance?: number = 5;
    intelligence?: number = 5;
    intelligenceother?: number = 5;
    intelligenceimportance?: number = 5;
    kidswish?: number = 5;
    kidswishother?: number = 5;
    kidswishimportance?: number = 5;
    politicsspectrum?: number = 5;
    politicsspectrumother?: number = 5;
    politicsspectrumimportance?: number = 5;
    religion?: number = 5;
    religionother?: number = 5;
    religionimportance?: number = 5;
    outgoing?: number = 5;
    outgoingother?: number = 5;
    outgoingimportance?: number = 5;
    openminded?: number = 5;
    openmindedother?: number = 5;
    openmindedimportance?: number = 5;
    bedpartners?: number = 5;
    bedpartnersother?: number = 5;
    bedpartnersimportance?: number = 5;
    sexdrive?: number = 5;
    sexdriveother?: number = 5;
    sexdriveimportance?: number = 5;
    readbooks?: number = 5;
    readbooksother?: number = 5;
    readbooksimportance?: number = 5;
    naturelover?: number = 5;
    natureloverother?: number = 5;
    natureloverimportance?: number = 5;
    exercise?: number = 5;
    exerciseother?: number = 5;
    exerciseimportance?: number = 5;
    inshape?: number = 5;
    inshapeother?: number = 5;
    inshapeimportance?: number = 5;
    creativeness?: number = 5;
    creativenessother?: number = 5;
    creativenessimportance?: number = 5;
    wealthy?: number = 5;
    wealthyother?: number = 5;
    wealthyimportance?: number = 5;
    maritalstatus?: number = 5;
    maritalstatusother?: number = 5;
    maritalstatusimportance?: number = 5;
    disabled?: number = 5;
    disabledother?: number = 5;
    disabledimportance?: number = 5;




  constructor(
    smoking?: number , 
    smokingother?: number , 
    smokingimportance?: number , 
    drugs?: number , 
    drugsother?: number , 
    drugsimportance?: number , 
    lengthother?: number , 
    lengthimportance?: number , 
    weightother?: number , 
    weightimportance?: number , 
    education?: number , 
    educationother?: number , 
    educationimportance?: number , 
    attractiveness?: number , 
    attractivenessother?: number , 
    attractivenessimportance?: number , 
    intelligence?: number , 
    intelligenceother?: number , 
    intelligenceimportance?: number , 
    kidswish?: number , 
    kidswishother?: number , 
    kidswishimportance?: number , 
    politicsspectrum?: number , 
    politicsspectrumother?: number , 
    politicsspectrumimportance?: number , 
    religion?: number , 
    religionother?: number , 
    religionimportance?: number , 
    outgoing?: number , 
    outgoingother?: number , 
    outgoingimportance?: number , 
    openminded?: number , 
    openmindedother?: number , 
    openmindedimportance?: number , 
    bedpartners?: number , 
    bedpartnersother?: number , 
    bedpartnersimportance?: number , 
    sexdrive?: number , 
    sexdriveother?: number , 
    sexdriveimportance?: number , 
    readbooks?: number , 
    readbooksother?: number , 
    readbooksimportance?: number , 
    naturelover?: number , 
    natureloverother?: number , 
    natureloverimportance?: number , 
    exercise?: number , 
    exerciseother?: number , 
    exerciseimportance?: number , 
    inshape?: number , 
    inshapeother?: number , 
    inshapeimportance?: number , 
    creativeness?: number , 
    creativenessother?: number , 
    creativenessimportance?: number , 
    wealthy?: number , 
    wealthyother?: number , 
    wealthyimportance?: number , 
    maritalstatus?: number , 
    maritalstatusother?: number , 
    maritalstatusimportance?: number , 
    disabled?: number , 
    disabledother?: number , 
    disabledimportance?: number 
) {
    this.smoking = smoking
    this.smokingother = smokingother
    this.smokingimportance = smokingimportance
    this.drugs = drugs
    this.drugsother = drugsother
    this.drugsimportance = drugsimportance
    this.lengthother = lengthother
    this.lengthimportance = lengthimportance
    this.weightother = weightother
    this.weightimportance = weightimportance
    this.education = education
    this.educationother = educationother
    this.educationimportance = educationimportance
    this.attractiveness = attractiveness
    this.attractivenessother = attractivenessother
    this.attractivenessimportance = attractivenessimportance
    this.intelligence = intelligence
    this.intelligenceother = intelligenceother
    this.intelligenceimportance = intelligenceimportance
    this.kidswish = kidswish
    this.kidswishother = kidswishother
    this.kidswishimportance = kidswishimportance
    this.politicsspectrum = politicsspectrum
    this.politicsspectrumother = politicsspectrumother
    this.politicsspectrumimportance = politicsspectrumimportance
    this.religion = religion
    this.religionother = religionother
    this.religionimportance = religionimportance
    this.outgoing = outgoing
    this.outgoingother = outgoingother
    this.outgoingimportance = outgoingimportance
    this.openminded = openminded
    this.openmindedother = openmindedother
    this.openmindedimportance = openmindedimportance
    this.bedpartners = bedpartners
    this.bedpartnersother = bedpartnersother
    this.bedpartnersimportance = bedpartnersimportance
    this.sexdrive = sexdrive
    this.sexdriveother = sexdriveother
    this.sexdriveimportance = sexdriveimportance
    this.readbooks = readbooks
    this.readbooksother = readbooksother
    this.readbooksimportance = readbooksimportance
    this.naturelover = naturelover
    this.natureloverother = natureloverother
    this.natureloverimportance = natureloverimportance
    this.exercise = exercise
    this.exerciseother = exerciseother
    this.exerciseimportance = exerciseimportance
    this.inshape = inshape
    this.inshapeother = inshapeother
    this.inshapeimportance = inshapeimportance
    this.creativeness = creativeness
    this.creativenessother = creativenessother
    this.creativenessimportance = creativenessimportance
    this.wealthy = wealthy
    this.wealthyother = wealthyother
    this.wealthyimportance = wealthyimportance
    this.maritalstatus = maritalstatus
    this.maritalstatusother = maritalstatusother
    this.maritalstatusimportance = maritalstatusimportance
    this.disabled = disabled
    this.disabledother = disabledother
    this.disabledimportance = disabledimportance
  }
   


}
