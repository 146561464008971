import React, { useRef } from 'react';

import { Avatar as DefaultAvatar } from 'stream-chat-react'

import type { ChannelPreviewUIComponentProps } from 'stream-chat-react'

import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from './types';

import { removeChannelById } from "../../helper/ChatHelper";
import AlertDialog from '../SHARED/AlertDialog';
import _ from 'lodash'


const UnMemoizedChannelPreviewMessenger = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,

  >(
    props: ChannelPreviewUIComponentProps<At, Ch, Co, Ev, Me, Re, Us>,
) => {
  const {
    active,
    Avatar = DefaultAvatar,
    channel,
    displayImage,
    displayTitle,
    latestMessage,
    setActiveChannel,
    unread,
    watchers,

  } = props;


  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

  const activeClass = active ? 'str-chat__channel-preview-messenger--active' : '';
  const unreadClass = unread && unread >= 1 ? 'str-chat__channel-preview-messenger--unread' : '';

  const avatarName =
    displayTitle || channel?.state?.messages[channel.state.messages.length - 1]?.user?.id;


  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(channel, watchers);


    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }

    console.log(channel, watchers)
  };

  const onRemoveChannel = async () => {
    console.log("Remove channel: ", channel)
    await removeChannelById(channel.id)

    window.location.reload(); //HACK NIET GOED
    //**************** Hier gebleven  Error: You can't use a channel after client.disconnect() was called */
  };

  const getCleanName = () => {

    //  console.log('Channel - ', channel)
    const self = _.get(channel, '_client.user.id')
    let name = _.get(channel, 'state.members')

    _.forOwn(name, function (value, key) {
      if (key !== self) {
        name = _.get(value, 'user.name')
      }
    });
    return name
  }



  return (
    <>
      <button
        className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
        data-testid='channel-preview-button'
        onClick={onSelectChannel}
        ref={channelPreviewButton}

      >
        <div className='str-chat__channel-preview-messenger--left'>
          <Avatar image={displayImage} name={avatarName} size={32} />
        </div>
        <div className='str-chat__channel-preview-messenger--right' >
          <div className='str-chat__channel-preview-title' >
            <span>{getCleanName()}</span>

          </div>
         {/*  <div className='str-chat__channel-preview-last-message'>{latestMessage}</div> 
 */}

        </div>

        <AlertDialog handleAgree={onRemoveChannel}
          icon="delete"
          okText="Yes, do it"
          cancelText="Never mind"
          subTitle="Do you want to delete this conversation?"
          title="Delete conversation"
          color="white" />


      </button>

    </>
  );
};

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const ChannelPreviewMessenger = React.memo(
  UnMemoizedChannelPreviewMessenger,
) as typeof UnMemoizedChannelPreviewMessenger;

export default ChannelPreviewMessenger;